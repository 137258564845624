<template>
  <div class="Navigation">
    <header class="header">
      <div class="wrap">
        <div class="flex f-jb top f-ac">
          <div class="logo" @click="index()"></div>
          <div class="flex f-ac">
            <!-- 导航栏 -->
            <el-menu v-if="true" router :default-active="onRoutes" mode="horizontal" background-color="#fd6c0b"
              active-text-color="#fff" @select="handleSelect">
              <!-- <el-menu-item
                v-for="(item, index) in list"
                :key="index"
                :index="item.address"
                >{{ item.name }}
              </el-menu-item> -->
              <!--
              <el-submenu index="">
                <template slot="title"
                  >
                <span :style="this.$i18n.locale === 'zh-CN' ? 'font-size:0.2rem !important' : ''">
                  {{ $t("navigation.event_introduction") }}
                  <i class="i_01" @click="eventIntroductionBtn()"></i>
                </span>
                </template>
<el-menu-item index="/eventIntroduction">
  <template slot="title">
                  <span :style="this.$i18n.locale === 'zh-CN' ? 'font-size:0.2rem !important' : ''">
                    {{
                  $t("navigation.season_introduction_21")

                }}
                    <i class="i_02" @click="newsBtn()"></i>
                  </span>
                </template>


</el-menu-item>
</el-submenu>
-->
              <el-menu-item index="/eventIntroduction">
                <span
                  :style="this.$i18n.locale === 'zh-CN' ? 'font-size:0.2rem !important' : 'font-size:0.2rem !important'">
                  {{ $t("navigation.event_introduction") }}
                  <i class="i_01" @click="eventIntroductionBtn()"></i>
                </span>

              </el-menu-item>
              <el-menu-item index="/matchSign">
                <span
                  :style="this.$i18n.locale === 'zh-CN' ? 'font-size:0.2rem !important' : 'font-size:0.2rem !important'">
                  {{
            $t("navigation.event_registration") }}</span>

              </el-menu-item>
              <el-submenu index="/news">
                <template slot="title">
                  <span
                    :style="this.$i18n.locale === 'zh-CN' ? 'font-size:0.2rem !important ' : 'font-size:0.2rem !important'">
                    {{ $t("navigation.event_information") }}
                    <i class="i_02" @click="newsBtn()"></i>
                  </span>
                </template>
                <el-menu-item index="/news">{{
            $t("navigation.event_consultation")
          }}</el-menu-item>
                <el-menu-item index="/down">{{
              $t("navigation.data_download")
            }}</el-menu-item>
                <el-menu-item index="/faq">{{
              $t("navigation.common_problem")
            }}</el-menu-item>
              </el-submenu>
              <!-- <el-submenu index="/matchReview2021">
                <template slot="title">{{ $t("navigation.event_review") }}<i class="i_03" @click="matchReview2021Btn()"></i>
                </template>
                <el-menu-item index="/matchReview2021">{{$t("navigation.season_2021")}}</el-menu-item>
                <el-menu-item index="/matchReview2020">{{$t("navigation.season_2020")}}</el-menu-item>
              </el-submenu> -->
              <el-submenu index="/matchReview?year=2024">
                <template slot="title">
                  <span
                    :style="this.$i18n.locale === 'zh-CN' ? 'font-size:0.2rem !important' : 'font-size:0.2rem !important'">
                    {{ $t("navigation.event_review")
                    }}<i class="i_03" @click="matchReview2021Btn()"></i>
                  </span>
                </template>
                <el-menu-item index="/matchReview?year=2024">{{
                    $t("navigation.season_2024")
                  }}</el-menu-item>
                <el-menu-item index="/matchReview?year=2023">{{
            $t("navigation.season_2023")
          }}</el-menu-item>
                <el-menu-item index="/matchReview?year=2022">{{
              $t("navigation.season_2022")
            }}</el-menu-item>
                <el-menu-item index="/matchReview?year=2021">{{
              $t("navigation.season_2021")
            }}</el-menu-item>
                <el-menu-item index="/matchReview?year=2020">{{
              $t("navigation.season_2020")
            }}</el-menu-item>
              </el-submenu>
              <el-menu-item index="/personal">
                <span
                  :style="this.$i18n.locale === 'zh-CN' ? 'font-size:0.2rem !important' : 'font-size:0.2rem !important'">{{
            $t("navigation.personal_center") }}</span>
              </el-menu-item>
            </el-menu>
            <div class="menu flex begin" v-if="false">
              <a :class="{ active: index == current }" v-for="(item, index) in list" :key="index"
                @click="change(index, item.address)">{{ item.name }}</a>
            </div>
            <div class="login flex f-ac">
              <img alt="登录" src="../assets/img/icon-login.png" class="icon" v-show="enter" @click="seen()" />
              <a class="res" @click="seen()" v-show="enter">{{
            $t("loginPage.login")
          }}</a>
              <img alt="退出" src="../assets/img/icon-account3.png" class="icon" v-show="exit" />
              <a class="res" @click="exits()" v-show="exit">{{
            $t("loginPage.loginout")
          }}</a>
              <div class="selectWrap">
                <el-select class="language" v-model="value" @change="toggleLang">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="alerts" id="vhs" v-show="defeated">
              <div class="alert">
                <img src="../assets/img/rg1.png" alt="" />
                <div>{{ $t("loginPage.logout_sucess") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import "../assets/css/index.css";
import "../assets/css/common.css";
import { addArticle } from "@/api/index";
export default {
  inject: ["reload"],
  data() {
    return {
      defeated: false,
      style: {
        color: "",
      },
      logo: [],
      list: [
        { name: "赛事介绍", address: "/eventIntroduction" },
        { name: "报名赛事", address: "/matchSign" },
        { name: "赛事信息", address: "/news" },
        { name: "赛事回顾", address: "/matchReview" },
        { name: "联系我们", address: "" },
        { name: "个人中心", address: "/personal" },
      ],
      current: 0,
      enter: true,
      exit: false,
      activeIndex: "1",
      routePath: null,
      options: [
        {
          value: "en-CN",
          label: "English",
        },
        {
          value: "zh-CN",
          label: "中文",
        },
      ],
      value: "zh-CN",
    };
  },
  name: "navigation",
  props: {},
  created() {
    // this.getList();
    const token = localStorage.getItem("token");
    if (token) {
      this.exit = true;
      this.enter = false;
    } else {
      this.enter = true;
      this.exit = false;
    }
    // 获取当前页面的路由
    // console.log(this.$route.path);
    this.routePath = this.$route.path;
  },
  mounted() {
    //用户每次刷新页面都判断 是否缓存过 语言，缓存过的话 选择其中显示的应该是缓存的语言
    this.value = localStorage.getItem("lang") || "zh-CN";
  },
  computed: {
    onRoutes() {
      //判断是否在子页面中
      if (
        this.$route.path == "/matchSignDetail" ||
        this.$route.path == "/sign"
      ) {
        return "/matchSign";
      } else if (this.$route.path == "/account") {
        return "/personal";
      } else if (
        this.$route.path == "/matchReviewDetail" ||
        this.$route.path == "/matchPhotos" ||
        this.$route.path == "/matchVideos"
      ) {
        // return "/matchReview2021";
        return "/matchReviewO";
      } else if (this.$route.path == "/news-details") {
        return "/news";
      } else {
        return this.$route.path;
      }
    },
  },
  methods: {
    handleSelect(key, keyPath) { },
    change(index, address) {
      this.$router.push(address);
      this.current = index;
    },
    toggleLang(lang) {
      this.$store.commit("change", lang); //修改state 和设置 缓存 lang
      this.$i18n.locale = lang;
      console.log("this.$i18n.locale", this.$i18n.locale);
      this.reload(); //刷新页面
    },
    getList() {
      addArticle().then((res) => {
        // this.logo = res.data.logo;
      });
    },
    exits() {
      this.defeated = true;
      if (this.defeated) {
        setTimeout(() => {
          this.defeated = false;
          // this.$router.push("/login");
          // 取出当前页面路由，如果是报名页跳转回报名详情，个人中心跳转回登录
          if (
            this.routePath == "/personal" ||
            this.routePath == "/account" ||
            this.routePath == "/submitWork"
          ) {
            this.$router.push("/login");
          } else if (
            this.routePath == "/sign" ||
            this.routePath == "/signOffline"
          ) {
            this.$router.push("/login");
          } else {
            // 登录和退出的按钮显示和隐藏
            this.enter = true;
            this.exit = false;
          }
        }, 2000);
      }
      localStorage.removeItem("token");
      localStorage.removeItem("signData");
      localStorage.removeItem("getCompetitionId");
      localStorage.removeItem("matchSignDetail");
      localStorage.removeItem("matchReviewDetailId");
    },
    index() {
      this.$router.push("/");
    },
    seen() {
      this.$router.push("/login");
    },
    eventIntroductionBtn() {
      this.$router.push("/eventIntroduction");
    },
    newsBtn() {
      this.$router.push("/news");
    },
    matchReview2021Btn() {
      // this.$router.push("/matchReview2021");
      this.$router.push("/matchReview?year=2023");
    },
  },
};
</script>
<style>
.el-select {
  /* width: 110px; */
  width: 120px;
}

.res:hover,
.icon:hover {
  cursor: pointer;
}

.el-menu {
  background-color: #fd6c0b !important;
  border: none !important;
  min-width: max-content;
}

.el-menu-item>span {
  height: 60px;
  line-height: 60px;
  font-size: 0.24rem !important;
}

.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 0.03rem solid #fff !important;
  color: #fff !important;
}

.el-menu--horizontal>.el-menu-item {
  color: #fff !important;
}

.el-menu--horizontal>.el-menu-item:hover {
  background-color: #fd6c0b !important;
  border-bottom: 0.03rem solid #fff !important;
}

/* 导航下拉样式 */
.el-submenu__title {
  /* background-color: #fd6c0b !important; */
  font-size: 0.24rem !important;
  color: #fff !important;
}

.el-submenu__title i {
  color: #fff !important;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #fff !important;
}

.el-menu--horizontal .el-menu .el-menu-item {
  color: #fff !important;
}

.i_01,
.i_02,
.i_03 {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.i_02 {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.selectWrap {
  margin-left: 20px;
}
</style>
